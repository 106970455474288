@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Poppins, "sans-serif" !important ;
  @apply text-dark;
}

.form-input {
  @apply focus:outline-secondary border-secondary w-full px-2 py-2 font-medium bg-white border rounded-lg;
}

.form-select {
  border-right: 16px solid transparent;
  @apply border-secondary focus:outline-secondary w-full px-2 py-2 font-medium border rounded-lg;
}

@layer base {
  html {
    font-family: Poppins;
    @apply text-dark;
  }
}

.animate-tick {
  animation-name: expand;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
}

@keyframes expand {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

ion-popover {
  --color: white;
  --width: 90%;
  min-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  background-color: #fafafa;
  height: 45px;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 0;
  @apply border-secondary focus:outline-secondary w-full px-2 py-2 font-medium border rounded-lg;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
