.swiper-pagination-bullet {
  border: 2px solid grey;
  width: 8px;
  height: 8px;
  background-color: white;
}

.swiper-pagination-bullet-active {
  background-color: var(--ion-color-primary);
  border: 2px solid white;
  opacity: 1;
}
